<template>
	<div>
		<ContentHeader title="Farmasi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
				<div class="card-header">
					<h3 class="card-title"><div>Farmasi</div></h3>

					<div class="card-tools">
					<button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
						<i class="fas fa-minus"></i>
					</button>
					</div>
				</div>
				<div class="card-body" style="display: block;">
					<table class="table table-bordered table-hover">
						<thead>
							<tr>
							<th scope="col">No</th>
							<th scope="col">Nomor Antrian</th>
							<th scope="col">NAMA</th>
							<th scope="col">NOMOR WHATSAPP</th>
							<th scope="col">STATUS</th>
							<th scope="col">POLI</th>
							<th scope="col">LAYANAN</th>
							<th scope="col">WAKTU KUNJUNGAN</th>
							<th scope="col">ACTION</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in pasienTelahDiperiksa" :key="row.id">
								<td scope="row">{{ index + 1 }}</td>
								<td>{{ row.nomor }}</td>
								<td>{{ row.pasien.nama }}</td>
								<td>{{ row.pasien.nomor_whatsapp }}</td>
								<td>{{ row.status }}</td>
								<td>{{ row.poli?.nama }}</td>
								<td>{{ row.layanan?.nama }}</td>
								<td>{{ dayjs(row.created_at).format('HH:mm') }}</td>
								<td>
								<div class="btn-group">
									<router-link :to="`/konfirmasi-obat-farmasi/${row.id}`" class="btn btn-sm btn-info">Proses</router-link>
								</div>
							</td>
							</tr>
						</tbody>
					</table>
					<p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
					<p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
				</div>
				<!-- /.card-body -->
				<div class="card-footer" style="display: block;">
					
				</div>
				<!-- /.card-footer-->
				</div>

			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import { ref, onMounted, computed } from 'vue'
import store from '@/store'
import dayjs from 'dayjs'

const memuat_data = ref(true)
const user = computed(() => store.getters['auth/user'])
const pasienTelahDiperiksa = ref()
const cek_data = ref(false)
const getPasienTelahDiperiksa = async () => {
	let response = await axios.get('api/kunjungan/getWhereTelahDiperiksa/' + user.value.cabang_id)
	
	if (response.data == 'kosong') {
		memuat_data.value = false
	cek_data.value = 'Data Masih Kosong'
	pasienTelahDiperiksa.value = null
	} else {
		memuat_data.value = false
	cek_data.value = false
	pasienTelahDiperiksa.value = response.data
	}	
}

// Refresh data 
setInterval(() => {
	getPasienTelahDiperiksa()		
}, 240000)
// End Refresh Data

onMounted(() => {
	getPasienTelahDiperiksa()
})
</script>